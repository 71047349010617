import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import style from "./index.module.css"

class Index extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="Portfolio"
          description="Official Website for Web Developer and Photographer, Andreas Karpasitis, based in Barcelona. Photography and Web Development Portfolio."
          keywords="Karpasitis, Andreas, Portfolio, Photo, Photography, Web, Developer, Photographer, Barcelona"
          url="https://www.karpasitis.net"
        />
        <div className={style.container}>
          <header style={{ marginTop: "48px" }}>
            <h1
              className={style.welcome}
              style={{
                padding: "0 15px 20px 15px"
              }}
            >
              Andreas Karpasitis
              <br />
              Photography and Web Development Portfolio
            </h1>
          </header>
          <div className={style.navigation}>
            <Link to="/art" className={style.linkArt}>
              Art
            </Link>
            <Link to="/travel" className={style.linkTravel}>
              Travel
            </Link>
            <Link to="/web" className={style.linkWeb}>
              Web
            </Link>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Index
